.date-range-container {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;

  .fa {
    margin-top: -15px;
    margin-left: 10px;
  }
}

.date-range {
  position: relative;
  display: flex;
  align-items: center;

  &.netsuite-date {
    margin-bottom: 20px;

    .date-range-buttons-container {
      width: 100%;
    }

    .btn-group {
      margin-bottom: 0;
    }
  }

  .btn-group {
    margin-bottom: 20px;
  }

  .fa {
    margin-top: 35px;
  }

  xp-select {
    margin-top: 7px;
  }

  .date-range-buttons-container {
    display: flex;
    flex-flow: column;
    width: 260px;
    min-width: 260px;
    padding-right: 20px;
    height: 120px;
  }

  .mat-form-field {
    margin-right: 20px;
  }

  .daterangepicker {
    .daterangepicker_input {
      display: none !important;
    }
    &.dropdown-menu {
      top: 107% !important;
      padding: 10px;
      min-width: 506px !important;
    }
  }
  .date-range-form-control {
    color: @text-color;
    display: flex;
    padding-top: 25px;
    .date-control {
      flex: 1 auto;
      position: relative;
      label {
        position: absolute;
        top: -26px;
      }
      i {
        position: absolute;
        top: 12px;
        left: 12px;
        z-index: 1;
      }
      input {
        z-index: 0;
        padding-left: 32px;
      }
    }
  }

  .date-symbol {
    width: 39px;
    text-align: center;
    line-height: 39px;
    margin-right: 30px;
    margin-top: 35px;
  }
}
