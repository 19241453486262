@connection-list-item-padding: 40px;
@connection-list-item-actions-width: 120px;

.connection-list-item(@generic-list-width) {
  &.connection {
    padding-bottom: 140px;
  }
  .connection-list-item {
    height: 120px;
    padding-left: 40px;
    display: grid;
    grid-template-columns: [connection-name] 1fr 1fr [type] 1fr [owner] 1fr [date] 1fr [dropdown] 80px;

    &.connection-group-list-item {
      grid-template-columns: [connection-name] 1fr 1fr 1fr [description] 1fr [owner] 1fr [date] 1fr [dropdown] 80px;
    }

    @media (max-width: @screen-sm) {
      grid-template-columns: [connection-name] 1fr [type] 1fr [date] 1fr [dropdown] 40px;
      padding-left: 10px;
    }

    .dropdown {
      @media (max-width: @screen-sm) {
        transform: rotate(90deg);
      }
    }

    .connection-name {
      grid-column: 1 / span 2;
      padding-top: 30px;
      line-height: 23px;
      display: flex;
      flex-direction: row;

      @media (max-width: @screen-sm) {
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        grid-column: 1 / span 1;
      }

      .input-checkbox {
        .input-checkbox-content {
          padding: 0;

          .fa {
            font-size: 20px;
            margin-top: -17px;
          }
        }
      }
      .icon {
        width: 30px;
        height: 30px;
        min-width: 30px;
        margin-bottom: 40px;

        @media (max-width: @screen-sm) {
          display: none;
        }

        &.icon-workflow {
          height: 60px;
        }
        margin-right: 15px;
        color: #999;
        flex: 0 auto;
      }
      div {
        padding-right: 50px;
        word-break: break-all;
        word-wrap: break-word;
        max-width: 100%;

        @media (max-width: @screen-sm) {
          padding: 0;
        }

        strong {
          padding-bottom: 4px;
          display: block;
          max-width: 100%;

          @media (max-width: @screen-sm) {
            text-align: center;
          }
          a {
            color: @gray-base;
            font-size: 16px;
            font-weight: 600;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 100%;
            display: block;
            &:hover {
              text-decoration: underline;
            }
          }
          .inactive {
            color: @gray;
            font-weight: 300;
            font-size: 12px;
            line-height: 20px;
            margin-left: 3px;
          }
        }
        .connection-key {
          color: @gray;
          font-weight: 300;
          font-size: 13px;
          cursor: pointer;

          @media (max-width: @screen-sm) {
            text-align: center;
            display: none;
          }

          i {
            display: none;
            margin-left: 5px;
          }
          &:hover {
            text-decoration: underline;
          }
        }

        .archived-connection-status {
          margin-left: 10px;
          font-size: 12px;
          color: @gray;
          cursor: pointer;

          .fa {
            color: red;
            margin-right: 5px;
          }
        }
      }
    }

    .connection-type,
    .connections-count {
      padding-top: 30px;

      @media (max-width: @screen-sm) {
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
      }
    }
    .connection-owner {
      padding-top: 30px;
      padding-right: 20px;

      @media (max-width: @screen-sm) {
        display: none;
      }

      span {
        display: block;
        color: @gray;
        font-weight: 300;
        font-size: 14px;
        padding-bottom: 3px;
      }
      a {
        color: @gray-base;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
        display: block;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .connection-date {
      padding-top: 30px;
      font-size: 13px;

      @media (max-width: @screen-sm) {
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
